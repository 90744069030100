
import { quoteMapActions, quoteMapState } from "@/store/modules/quote";
import quoteRiskAddressForm from "@/forms/shared/quote.risk_address";
import Vue from "vue";
import { cloneDeep, omit, pick } from "lodash";
import { set } from "lodash";
import RiskAddressScrubbingFailedModal from "./RiskAddressScrubbingFailedModal.vue";
import RiskAddressScrubbingSuccessModal from "./RiskAddressScrubbingSuccessModal.vue";
import { propertyChangeRequestMapActions } from "@/store/modules/propertychangerequest";
import { objectDifference } from "@/helpers";
import { defaultAddressStructure } from "@/helpers/defaultObjects";
import { ErrorWrapper } from "@/services/error_wrapper";
import { getFieldSchema } from "@/components/FormBuilder/Helpers/utils/getFieldSchema";
import {
  isValidFutureEffectiveDate,
  isValidPastEffectiveDate
} from "@/helpers/validateEffectiveDate";
import { get } from "lodash";
import { isExemptedUserRole } from "@/helpers/index";
import { stripAgencyCode } from "@/helpers/generalHelpers";
import UnderwriterInfo from "./Components/UnderwriterInfo.vue";
import { convertCamelPropNameToNormalString } from "@/helpers/camelCaseToTitleCase";

export default Vue.extend({
  components: {
    RiskAddressScrubbingFailedModal,
    RiskAddressScrubbingSuccessModal,
    UnderwriterInfo,
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  name: "risk-address",
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  data(): any {
    return {
      quoteRiskAddressForm,
      riskAddressData: {
        riskAddress: {
          ...defaultAddressStructure
        },
        mailingAddress: {
          ...defaultAddressStructure,
          isSameAsPhysicalAddress: null
        },
        quoteApplication: {
          applicant: {
            firstName: "",
            middleName: "",
            lastName: ""
          }
        },
        createdOn: new Date(),
        effectiveDate: "",
        distantToFireHydrantFt: "",
        distantToFireStationMi: "",
        distanceToCoast: ""
      },
      validation: {},
      showAddressValidationFailedModal: false,
      showAddressValidationSuccessModal: false,
      addressScrubbingResponse: {
        countyMatches: []
      },
      hasValidDistance: false,
      loadingText: "",
      genericErrorMessage: ""
    };
  },
  created() {
    this.riskAddressData.riskAddress = {
      ...this.riskAddressData.riskAddress,
      ...this.editing.riskAddress
    };
    if (this.editing.mailingAddress) {
      this.riskAddressData.mailingAddress = {
        ...this.riskAddressData.mailingAddress,
        ...this.editing.mailingAddress
      };
    }
    if (this.editing.quoteApplication !== null) {
      const quoteApplication = omit(this.editing.quoteApplication, [
        "applicant.ssn",
        "applicant._id",
        "applicant.dateOfBirth",
        "applicant.maritalStatus",
        "applicant.id",
        "coApplicants"
      ]);
      this.riskAddressData.quoteApplication = {
        ...quoteApplication
      };
    }

    this.riskAddressData.distantToFireHydrantFt = this.editing.distantToFireHydrantFt;
    //so it doesn't break existing quote since data type has been changed in schema to string
    if (
      this.editing.distantToFireStationMi &&
      typeof this.editing.distantToFireStationMi !== "string"
    ) {
      this.riskAddressData.distantToFireStationMi = this.editing?.distantToFireStationMi?.toString();
    }
    this.riskAddressData.distanceToCoast = this.editing.distanceToCoast;
    this.riskAddressData.effectiveDate = this.editing.effectiveDate
      ? this.editing.effectiveDate
      : "";
    this.riskAddressData.riskAddress.state = this.editing.riskAddress.state
      ? this.editing.riskAddress.state
      : "TX";
    this.createdOn = this.editing.createdOn;
    /**
     * For the Curious
     * Effective date has nothing to do with risk address as far as this
     * screen is concerned. The requirement however was that they should be
     * able to set effective date on this screen. I decided to make it so
     * that they set effective date only once. Subsequently, this field will be
     * disabled. We want them to only modify it on the property rating screen
     */
    const effectiveDateField = getFieldSchema(
      quoteRiskAddressForm(this.$getCurrentUser, this.createdOn),
      "effectiveDate"
    );
    if (effectiveDateField && this.hasValidRiskAddress) {
      effectiveDateField.properties.disabled = true;
    }
  },
  mounted() {
    this.maybeThrowInvalidEffectiveDateError();
  },
  methods: {
    ...propertyChangeRequestMapActions(["scrubAddress"]),
    ...quoteMapActions(["scrubQuoteAddress", "saveStep2RiskAddress"]),
    formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      if (key === "riskAddress") {
        const { fieldKey, value: fieldValue } = value;
        set(this.riskAddressData.riskAddress, fieldKey, fieldValue);
      } else if (key === "mailingAddress") {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey === "isSameAsPhysicalAddress") {
          if (fieldValue) {
            this.riskAddressData.mailingAddress = {
              ...this.riskAddressData.riskAddress,
              isSameAsPhysicalAddress: true
            };
          } else {
            this.riskAddressData.mailingAddress = {
              ...defaultAddressStructure,
              isSameAsPhysicalAddress: false
            };
          }
        } else {
          set(this.riskAddressData.mailingAddress, fieldKey, fieldValue);
        }
      } else if (
        key === "quoteApplication.applicant.firstName" ||
        key === "quoteApplication.applicant.middleName" ||
        key === "quoteApplication.applicant.lastName"
      ) {
        set(this.riskAddressData, key, value.trim().toUpperCase());
      } else if (
        ["distantToFireHydrantFt", "distantToFireStationMi"].includes(key)
      ) {
        if (isNaN(value) || value == "") {
          this.hasValidDistance = false;
        } else {
          if (key === "distantToFireStationMi") {
            value = parseInt(value);
          }
          this.hasValidDistance = true;
        }
        Vue.set(this.riskAddressData, key, value);
      } else {
        Vue.set(this.riskAddressData, key, value);
      }
      this.maybeThrowInvalidEffectiveDateError();
      this.riskAddressData = cloneDeep(this.riskAddressData as any);
    },
    async saveRiskAddress() {
      try {
        const id = this.$route.params.quoteId;
        if (Object.keys(this.updatedFields).length) {
          if (this.riskAddressData.mailingAddress.isSameAsPhysicalAddress) {
            this.riskAddressData.mailingAddress = {
              ...this.riskAddressData.riskAddress
            };
          }

          await this.saveStep2RiskAddress({
            payload: this.riskAddressData,
            id
          });
        }
        this.$router.push(`/quotes/${id}/territory`);
      } catch (error) {
        const { message } = error as Error;
        this.genericErrorMessage = convertCamelPropNameToNormalString(message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    async toolbarSelectItem(event: string): Promise<void> {
      switch (event) {
        case "validate": {
          if (
            this.riskAddressData.riskAddress &&
            ["TX", "TEXAS"].includes(this.riskAddressData.riskAddress.state)
          ) {
            try {
              this.loadingText = "Validating address. Please wait";
              const response = await this.scrubQuoteAddress({
                id: this.$route.params.quoteId,
                payload: {
                  riskAddress: this.riskAddressData.riskAddress,
                  quoteApplication: this.riskAddressData.quoteApplication
                }
              });
              this.addressScrubbingResponse = response.data;
              if (response.data.error && response.data.error.length > 0) {
                this.showAddressValidationFailedModal = true;
              } else {
                this.showAddressValidationSuccessModal = true;
              }
            } catch (error) {
              this.showAddressValidationFailedModal = true;
              this.$bugSnagClient.notify(error);
            } finally {
              this.loadingText = "";
            }
          } else {
            this.$notify.error(
              "Sorry, Atlas General Agency's services are only available in Texas"
            );
          }

          break;
        }
        case "next":
          await this.saveRiskAddress();

          break;
        case "cancel":
          this.$router.go(-1);
          break;
      }
    },
    async saveAddressAndCountyData(county: string) {
      if (!county || county.trim().length === 0) {
        this.$appNotifyInfo(
          "Please enter a valid county for the address entered"
        );
        return;
      }
      try {
        this.loadingText = "Saving address. Please wait...";
        if (this.hasRatings) {
          //show ratings invalidation prompt
          const message =
            "Updating risk address will invalidate all ratings and selected ratings";
          await this.$confirm(`${message}. Continue?`, "Warning", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            closeOnClickModal: false,
            showClose: false,
            cancelButtonClass:
              "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
          });
        }
        const {
          scrubbedAddress,
          originalAddress
        } = this.addressScrubbingResponse;
        const otherProps = this.showAddressValidationFailedModal
          ? originalAddress
          : pick(originalAddress, [
              "country",
              "line2",
              "unitNumber",
              "isPOBoXFormat",
              "isSameAsPhysicalAddress"
            ]);

        const joinedAddress = {
          ...scrubbedAddress,
          ...otherProps,
          county
        };
        this.riskAddressData.riskAddress = joinedAddress;

        //complete step-2 here
        await this.saveRiskAddress();
      } catch (error) {
        if (error !== "cancel") {
          if (error instanceof ErrorWrapper) {
            this.$notify.error(error.message);
          }
          this.$bugSnagClient.notify(error);
        }
      } finally {
        this.loadingText = "";
      }
    },
    maybeThrowInvalidEffectiveDateError(): void {
      const effectiveDate = this.riskAddressData.effectiveDate;
      const agentCode = stripAgencyCode(
        get(this.$getCurrentUser, "agentCode", "")
      ).toLowerCase();
      const exemptedRole = isExemptedUserRole(
        get(this.$getCurrentUser, "role", "")
      );
      const agentCodeFrmk = agentCode === "frmk";

      if (effectiveDate) {
        if (!isValidFutureEffectiveDate(effectiveDate)) {
          this.$appNotifyError(
            "Effective date is more than 120 days in the future"
          );
          this.$set(this.riskAddressData, "effectiveDate", "");
          return;
        } else if (
          !agentCodeFrmk &&
          !exemptedRole &&
          !isValidPastEffectiveDate(effectiveDate, 4)
        ) {
          this.$appNotifyError(
            "Backdating of Coverage Requires Underwriting Approval. Please Call Your Underwriter"
          );
          this.$set(this.riskAddressData, "effectiveDate", "");
          return;
        }
      }
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    hasProvidedValidRiskAndMailingAddress(): boolean {
      let addressTypes = ["riskAddress", "mailingAddress"];
      if (this.riskAddressData.mailingAddress.isSameAsPhysicalAddress) {
        addressTypes = ["riskAddress"];
      }
      return addressTypes.every(type => {
        return Object.keys(
          omit(this.riskAddressData[type], [
            "county",
            "_id",
            "unitNumber",
            "isPOBoXFormat"
          ])
        )
          .map((key: string) => {
            return !!this.riskAddressData[type][key];
          })
          .every((criteria: boolean) => criteria);
      });
    },
    updatedFields(): any {
      const fields = [
        "distantToFireHydrantFt",
        "distantToFireStationMi",
        "distanceToCoast",
        "riskAddress",
        "quoteApplication",
        "mailingAddress",
        "effectiveDate"
      ];
      const edited = pick(this.riskAddressData, fields);
      const original = pick(this.editing, fields);
      return edited && original
        ? objectDifference(edited, original, [
            "riskAddress.streetType",
            "riskAddress.streetDirection",
            "mailingAddress.streetType",
            "mailingAddress.streetDirection",
            "mailingAddress.isSameAsPhysicalAddress",
            "mailingAddress.unitNumber"
          ])
        : {};
    },
    canValidateAddress(): boolean {
      if (this.validation.formIsValid && this.hasValidAddressEditing) {
        // disable form if distance to fire station is not valid
        if (
          this.editing &&
          this.editing.distantToFireStationMi &&
          !this.hasValidDistance
        ) {
          return false;
        }
        if (this.hasValidRiskAddress) {
          return Object.keys(this.updatedFields).length > 0;
        }
        return true;
      }
      return false;
    },
    canProceed(): boolean {
      return this.validation.formIsValid && this.hasValidAddressEditing;
    },
    hasValidRiskAddress(): boolean {
      const ratingValidations = get(this.editing, "ratingValidations", {});
      return !!(ratingValidations && ratingValidations.hasValidRiskAddress);
    },
    quoteHasBeenSubmitted(): boolean {
      return !!(
        this.editing &&
        this.editing.status === "submitted" &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasPolicyNumber
      );
    },
    hasRatings(): boolean {
      return !!(
        this.editing &&
        this.editing.ratings &&
        this.editing.ratings.length > 0
      );
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    hasValidAddressEditing(): boolean {
      let addressTypes = ["riskAddress", "mailingAddress"];
      if (this.riskAddressData.mailingAddress.isSameAsPhysicalAddress) {
        addressTypes = ["riskAddress"];
      }
      if (!this.riskAddressData.mailingAddress.isSameAsPhysicalAddress) {
        addressTypes = ["mailingAddress"];
      }
      return addressTypes.every(addressType => {
        const dataToValidate =
          this.riskAddressData[addressType] &&
          this.riskAddressData[addressType].state &&
          this.riskAddressData[addressType].state.trim() &&
          this.riskAddressData[addressType].city &&
          this.riskAddressData[addressType].city.trim() &&
          this.riskAddressData[addressType].zipCode &&
          this.riskAddressData[addressType].zipCode.trim();
        if (addressType === "riskAddress") {
          return (
            this.riskAddressData &&
            dataToValidate &&
            this.riskAddressData[addressType].houseNumber &&
            this.riskAddressData[addressType].houseNumber.trim() &&
            this.riskAddressData[addressType].streetName &&
            this.riskAddressData[addressType].streetName.trim()
          );
        } else if (
          addressType === "mailingAddress" &&
          this.riskAddressData.mailingAddress.isPOBoXFormat &&
          this.riskAddressData.mailingAddress.unitNumber &&
          this.riskAddressData.mailingAddress.unitNumber.trim()
        ) {
          return this.riskAddressData && dataToValidate;
        } else if (
          addressType === "mailingAddress" &&
          !this.riskAddressData.mailingAddress.isPOBoXFormat
        ) {
          return dataToValidate;
        }
        return (
          this.riskAddressData &&
          dataToValidate &&
          this.riskAddressData[addressType].houseNumber &&
          this.riskAddressData[addressType].houseNumber.trim() &&
          this.riskAddressData[addressType].streetName &&
          this.riskAddressData[addressType].unitNumber &&
          this.riskAddressData[addressType].unitNumber.trim() &&
          this.riskAddressData[addressType].streetName.trim()
        );
      });
    }
  }
});
